<template>
<div id="app-container" ref="app-container">
  <div class="scroll-anchor"></div>
  <div class="viewframe">
    <div class="">
      <div>
        <img src="./assets/logo.png"  id="logo" alt="">
        <h2>Mental erleben</h2>
        <p>Werte...Ziele...Freude...Innere Kräfte</p>
      </div>
      <div class="divline"></div>
      <h1>...Das Glück unseres Lebens hängt von der Beschaffenheit unserer Gedanken ab...</h1>
      <div class="divline"></div>
      <div>
        <h2>Mentaltraining ist eine einfache Methode, um das Leben mit mehr Freude und Energie zu meistern!</h2>
        <p>Es geht dabei um die Entwicklung einer positiven Grundeinstellung und Haltung.
          Der Fokus wird auf das Positive gelegt, ohne dabei das Negative zu verdrängen.
          Ziel ist ein lösungsorientiertes Denken und ein konstruktives Herangehen
          an Aufgaben und Herausforderungen.</p>
        <div  @click="$refs['app-container'].scrollBy(0, 1000);">
          <img src="./assets/chevron-down.svg" alt="" id="chevron">
        </div>
      </div>
    </div>
  </div>
  <div class="viewframe white">
    <div>
      <div>
        <p>
          rf-mental <br>
          Rita Fuchs <br>
          Haus im Ennstal <br>
          Tel.: <a href="tel:+436641805472">+43 664/1805472</a><br>
          Mail: <a href="mailto:rf-mental@gmx.at">rf-mental@gmx.at</a>
        </p>
      </div>
      <img id="background" src="./assets/background.jpg" alt="Hintergrund">

      <div class="divline"></div>
      <div>
        <h2>
          <div class="lbr">...jeder Weg beginnt </div>&nbsp;
          <div class="lbr">mit dem ersten Schritt...</div></h2>
        <p>
          Leistungssteigerung in allen Ebenen <br>
          Gesundheit <br>
          Selbstmanagement <br>
          Schicksalsbewältigung <br>
          Findung des Lebenssinnes <br>
          Freude und Erfolg <br>
        </p>
      </div>
      <div class="divline"></div>
      <div>
        <h2>Mentales Training ist<wbr> für jeden anwendbar!</h2>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@font-face {
  font-family: "Tempus Sans ITC";
  src: url(./assets/TEMPSITC.TTF);
}


  #app-container {
    text-align: center;
    font-family: "Tempus Sans ITC", "Arial Rounded MT Bold", Arial,sans-serif;
    font-size: 12pt;
    position: relative;
    width: 100%;
    height: 100vh;
    scroll-behavior: smooth;
    overflow: auto;
    scroll-snap-type: y mandatory;
  }

#text867 {
  font-family: "Tempus Sans ITC", "Arial Rounded MT Bold", Arial,sans-serif;
}

.scroll-anchor {
  scroll-snap-align: end;
  height: 1px;

}
.viewframe:not(:last-child) {
  position: sticky;
  top: 1px;
  scroll-snap-align: none;
}
.viewframe:last-child {
  z-index: 1;
}

  p, b {
    font-size: 14pt;
  }

  @media screen and (max-width: 800px) {
    #app-container {
      font-size: 8pt;
    }

    p {
      font-size: 12pt;
      margin-bottom: 6pt;
    }

    h2 {
      font-size: 14pt;
      margin-top: 5pt;

    }

    .viewframe > div > :first-child {
      margin-top: 5pt !important;
    }
    .viewframe > div > :last-child {
      padding-bottom: 45pt;
    }
  }

  .viewframe {
    height: 100vh;
    width: 100%;
    scroll-snap-align: end;
    position: relative;
    overflow-x: hidden;
  }

  .viewframe > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    overflow: hidden;
  }


  .viewframe > div > div, .viewframe > div > h1 , .viewframe > div > h2, .viewframe > div > p {
    max-width: 600pt;
    margin: 0 20pt;
  }

  .white {
    color: white;
  }

  .white a {
    color: white;
  }

  #background {
    min-width: 100%;
    height: 100vh;
    margin: 0;
    object-fit: cover;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .divline {
    border-top: 1pt solid black;
    width: 90vw;
  }

  .white .divline {
    border-color: white;
  }

  #logo {
    display: block;
    margin: 8pt auto 0 auto;
    height: 8em;
  }

  .lbr {
    display: inline-block;
  }

  #chevron {
    width: 3em;
  }
</style>
